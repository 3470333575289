import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsGrid } from '@mui/x-charts/ChartsGrid';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { ChartsLegend } from '@mui/x-charts/ChartsLegend';
import classes from './PublicationsGraph.module.css';

// images
import EmptyPublicationsIcon from '../../../assets/icons/EmptyPublications.svg';

const toSpanishNumberFormat = (number) => {
    if (number === null || number === undefined) return 'N/A';

    let numStr = number.toString().replace(/\D/g, '');
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? ',' + parts[1] : '';
    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return formattedInteger + decimalPart;
}


const PublicationsGraph = ({ selectedPublication, selectedRowId, selectedColumn, graphData, tableData }) => {

    const getLabelForColumn = (column) => {
        switch (column) {
            case 'precio':
                return { myProduct: 'Precio publicación', rivalProduct: 'Precio rival' };
            case 'stock':
                return { myProduct: 'Stock publicación', rivalProduct: 'Stock rival' };
            case 'palabras':
                return { myProduct: 'Palabras publicación', rivalProduct: 'Palabras rival' };
            case 'imagenes':
                return { myProduct: 'Imágenes publicación', rivalProduct: 'Imágenes rival' };
            case 'reviews':
                return { myProduct: 'Reviews publicación', rivalProduct: 'Reviews rival' };
            case 'comentarios':
                return { myProduct: 'Comentarios publicación', rivalProduct: 'Comentarios rival' };
            default:
                return { myProduct: 'Publicación', rivalProduct: 'Rival' };
        }
    };
  
    const labels = getLabelForColumn(selectedColumn);
  
    const series = [
    {
        type: 'line',
        id: 'myProduct',
        dataKey: 'myProduct',
        color: '#000000',    
        showMark: false,
        label: labels.myProduct
    },
    {
        type: 'line',
        dataKey: 'rivalProduct',
        color: '#EB3223',
        showMark: false,
        label: labels.rivalProduct
    },
    {
        type: 'bar',
        id: 'sales',
        color: '#CDEDEC',
        dataKey: 'sales',
        yAxisId: 'rightAxis',
        label: 'Ventas publicación'
    },
    {
        type: 'bar',
        id: 'rivalSales',
        color: '#F9C2BD',
        dataKey: 'rivalSales',
        yAxisId: 'rightAxis',
        label: 'Ventas rival'
    },
    ];

    const [margin, setMargin] = useState({ left: 100, bottom: 70 });

    const handleResize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 460) {
            setMargin({ left: 50, bottom: 125 });
        } else if (screenWidth < 600) {
            setMargin({ left: 80, bottom: 100 });
        } else if (screenWidth < 700) {
            setMargin({ left: 80, bottom: 90 });
        } else if (screenWidth < 1160) {
            setMargin({ left: 80, bottom: 80 });
        }else {
            setMargin({ left: 100, bottom: 70 });
        }
    };

    useEffect(() => {
        handleResize(); // Set the initial margin based on current screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup event listener on unmount
        };
    }, []);

    const isRivalsOnTable = selectedRowId && tableData?.length > 0;
    const noRivalGraphData = graphData.map(data => {
        // Create a copy of the object without the `rival` attribute
        const { rival, ...noRivalData } = data;
        return noRivalData;
    });
  
    // Find the statistics entry for the selectedRowId
    const selectedStats = isRivalsOnTable ? graphData.find(stat => stat.rival_id === selectedRowId) : noRivalGraphData[0];

    // Extract the relevant data for myProduct and rivalProduct
    const myProductData = selectedStats?.myPublication?.map(data => data[Object.keys(data)[0]]) || [];
    const rivalProductData = selectedStats?.rival?.map(data => data[Object.keys(data)[0]]) || [];
    const rivalSalesData = selectedStats?.rivalSales || [];  

    // Create a dataset from the extracted data
    const dataset = (selectedPublication.statistics?.daily_sales_summary || []).map((entry, index) => {
        const [year, month, day] = entry.date.split('-');
        const myProductValue = isNaN(myProductData[index]) ? 0 : myProductData[index];
        const rivalProductValue = isNaN(rivalProductData[index]) ? 0 : rivalProductData[index];
        const salesValue = isNaN(entry.total_sales) ? 0 : entry.total_sales;    
        const rivalSales = rivalSalesData[index]?.sales === null ? null : rivalSalesData[index]?.sales || 0;

        return {
            day: `${day}.${month}`, // Convert date to day-month format
            myProduct: myProductValue,  // Handle invalid numbers
            rivalProduct: rivalProductValue,  // Handle invalid numbers
            sales: salesValue,  // Handle invalid numbers
            rivalSales: rivalSales,  // Handle rival sales data
        };
    });  

    const CustomItemTooltipContent = (props) => {
        const { axisData, series } = props;
        const { x } = axisData;
        const { index } = x;

        const myProductSeries = series.find(s => s.dataKey === 'myProduct');
        const rivalProductSeries = series.find(s => s.dataKey === 'rivalProduct');
        const salesSeries = series.find(s => s.dataKey === 'sales');

        const myProductSales = toSpanishNumberFormat(myProductSeries?.data[index]) ?? 'N/A';
        const rivalProductSales = toSpanishNumberFormat(rivalProductSeries?.data[index]) ?? 'N/A';
        const sales = toSpanishNumberFormat(salesSeries?.data[index]) ?? 'N/A';
        const rivalSales = rivalSalesData[index]?.sales;

        let tooltipContent = null;

        switch (selectedColumn) {
            case 'stock':
            tooltipContent = (
                <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
                <span style={{ fontWeight: 700 }}>{x.value}</span>
                <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> y te quedaron</span> 
                <span style={{ fontWeight: 700 }}>{myProductSales} unidades.</span>
                { rivalSales ? 
                    (
                    <>
                        <span> Est. de venta competencia <span style={{ fontWeight: 700 }}>{rivalSales} </span></span>
                        <span><span style={{ fontWeight: 700 }}>unidades,</span> stock: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    ) 
                    : 
                    (
                    <>
                        <span> No podemos estimar la venta de la </span>
                        <span>competencia, stock: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    )
                }                                  
                </div>
            );
            break;
            case 'palabras':
            tooltipContent = (
                <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
                <span style={{ fontWeight: 700 }}>{x.value}</span>
                <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> con <span style={{ fontWeight: 700 }}>{myProductSales}</span></span>            
                <span><span style={{ fontWeight: 700 }}>palabras</span> en descripción.</span> 
                { rivalSales ? 
                    (
                    <>
                        <span>Est. de venta competencia <span style={{ fontWeight: 700 }}>{rivalSales} </span></span>
                        <span><span style={{ fontWeight: 700 }}>unidades,</span> palabras descripción: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    ) 
                    : 
                    (
                    <>
                        <span> No podemos estimar la venta de la </span>
                        <span>competencia, palabras descripción: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    )
                }             
                </div>
            );
            break;
            case 'imagenes':
            tooltipContent = (
                <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
                <span style={{ fontWeight: 700 }}>{x.value}</span>
                <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> con <span style={{ fontWeight: 700 }}>{myProductSales != 1 ? `${myProductSales} imágenes.` : `${myProductSales} imagen.`} </span></span>            
                { rivalSales ? 
                    (
                    <>
                        <span>Est. de venta competencia <span style={{ fontWeight: 700 }}>{rivalSales} </span></span>
                        <span><span style={{ fontWeight: 700 }}>unidades,</span> imágenes: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    ) 
                    : 
                    (
                    <>
                        <span> No podemos estimar la venta de la </span>
                        <span>competencia, imágenes: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    )
                }            
                </div>
            );
            break;
            case 'comentarios':
            tooltipContent = (
                <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
                <span style={{ fontWeight: 700 }}>{x.value}</span>
                <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> y tuviste <span style={{ fontWeight: 700 }}>{myProductSales}</span></span>                        
                <span style={{ fontWeight: 700 }}> {myProductSales != 1 ? ` comentarios.` : `comentario.`}</span>
                { rivalSales ? 
                    (
                    <>
                        <span>Est. de venta competencia <span style={{ fontWeight: 700 }}>{rivalSales} </span></span>
                        <span><span style={{ fontWeight: 700 }}>unidades,</span> comentarios: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    ) 
                    : 
                    (
                    <>
                        <span> No podemos estimar la venta de la </span>
                        <span>competencia, comentarios: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    )
                }             
                </div>
            );
            break;
            case 'reviews':
            tooltipContent = (
                <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
                <span style={{ fontWeight: 700 }}>{x.value}</span>
                <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> y tuviste <span style={{ fontWeight: 700 }}>{myProductSales != 1 ? `${myProductSales} reviews.` : `${myProductSales} review.`}</span></span>                        
                { rivalSales ? 
                    (
                    <>
                        <span>Est. de venta competencia <span style={{ fontWeight: 700 }}>{rivalSales} </span></span>
                        <span><span style={{ fontWeight: 700 }}>unidades,</span> reviews: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    ) 
                    : 
                    (
                    <>
                        <span> No podemos estimar la venta de la </span>
                        <span>competencia, reviews: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    )
                }             
                </div>
            );
            break;
            default: // 'precio'
            tooltipContent = (
                <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
                <span style={{ fontWeight: 700 }}>{x.value}</span>
                <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> a un precio </span>            
                <span style={{ fontWeight: 700 }}>${myProductSales}.</span>
                { rivalSales ? 
                    (
                    <>
                        <span>Est. de venta competencia <span style={{ fontWeight: 700 }}>{rivalSales} </span></span>
                        <span><span style={{ fontWeight: 700 }}>unidades,</span> precio: <span style={{ fontWeight: 700 }}>${rivalProductSales}</span></span>
                    </>
                    ) 
                    : 
                    (
                    <>
                        <span> No podemos estimar la venta de la </span>
                        <span>competencia, precio: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
                    </>
                    )
                }             
                </div>
            );
            break;
        }

        return tooltipContent;
    };
  

    return (
        <div className={selectedStats?.myPublication ? classes.secondInnerGraphContainer : classes.secondInnerGraphContainer2}>
            {selectedStats?.myPublication ? 
            (
                <Box sx={{ width: '100%', height: '100%' }}>
                <ResponsiveChartContainer
                    series={series}
                    xAxis={[
                    {
                        scaleType: 'band',
                        dataKey: 'day',
                        disableTicks: true,
                    },
                    ]}
                    yAxis={[
                    { id: 'leftAxis', min: 0, disableTicks: true, valueFormatter: (value) => value.toLocaleString('es-CL') }, // tickNumber: 4 to set the number of data on the axis
                    { id: 'rightAxis', min: 0, disableTicks: true, valueFormatter: (value) => value.toLocaleString('es-CL')}, // to set invisible tickLabelStyle: { visibility: 'hidden' }
                    ]}
                    dataset={dataset}
                    margin={margin}                            
                >
                    <ChartsGrid horizontal />
                    <BarPlot borderRadius={5} />
                    <LinePlot />
                    <MarkPlot />
                    <ChartsXAxis />
                    <ChartsYAxis axisId="leftAxis"  position="left" />
                    <ChartsYAxis axisId="rightAxis" position="right" />
                    <ChartsAxisHighlight x="band" />
                    <ChartsAxisHighlight y="none" />
                    <ChartsLegend
                    direction="row"
                    position={{ vertical: 'bottom', horizontal: 'middle' }}
                    padding={0}
                    slotProps={{
                        legend: {
                        labelStyle: {
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fill: '#D3D3D3',
                        },
                        itemMarkWidth: 10,
                        itemMarkHeight: 10
                        },
                    }}
                    />
                    <ChartsTooltip 
                    trigger="axis" 
                    slots={{
                        axisContent: CustomItemTooltipContent
                    }}
                    />
                </ResponsiveChartContainer>
                </Box>
            ) 
            : 
            (
                <div className={classes.dataNotFound}>
                <div className={classes.noPublicationsContainer}>
                    <img
                        className={classes.publicationNotFoundIcon}
                        src={EmptyPublicationsIcon}
                        alt="empty-publications-icon"
                    />
                    <div className={classes.publicationNotFoundtextContainer}>
                        <span>Upsss... Por aquí no</span>
                        <span>encuentro información...</span>
                        <span className={classes.tryLaterText}>¡Intentalo más tarde!</span>
                    </div>
                </div>
                </div>
            )
            }      
        </div>
    );
};

export default PublicationsGraph;
