import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './PublicationCard.module.css';
import ProgressBar from './ProgressBar';
import PublicationModal from './PublicationModal';
import EmptyPublications from './EmptyPublications';
import Checkbox from '../../../components/UI/Checkbox';
import AlertTooltip from './UI/AlertTooltip';
import PublicationStatus from './PublicationStatus';

//images
import Canasta from '../../../assets/img/canasta.png';
import Ganando from '../../../assets/img/ganando 2.png';
import Perdiendo from '../../../assets/img/Perdiendo 2.png';
import Empatando from '../../../assets/img/Empatando 2.png';
import Bell from '../../../assets/img/icons/alert-bell.svg';
import Redirect from '../../../assets/img/icons/redirect-vector.svg';
import infoFortooltipIcon from '../../../assets/img/icons/info-tooltip.svg';
import Copy from '../../../assets/img/icons/copy-vector.svg';

const PublicationCard = ({
    product,
    checked,
    onCheckboxChange,
    deselectedIds = [],
    setDeselectedIds,
    selectAllPagesChecked = false,
    alertSByPublication = [],
    innercardWrapper,
    leftSideContainer,
    rightSideContainer,
    cardWrapper,
    checkedCard,
    firstcardCheckContainer,
    cardCheckContainer,
    cardImgWrapper,
    cardImg,
    cardTextWrapper,
    cardPercentageWrapper,
    percentageWrapper,
    cardInfoWrapper,
    textWrapperHeader,
    textNameWrapper,
    textName,
    textidsWrapper,
    titleInfoWrapperHealthcheck,
    titleInfoWrapperBuybox,
    titleInfoWrapperHealthBuybox,
    titleInfo,
    titleInfoHealthBuybox,
    flexWrapper,
    statusContainer,
    titleInfoWrapperInformation,
    cardInfoLogoWrapper,
    unitWrapper,
    renderCardCheckContainer,
    renderCardImgWrapper,
    renderCardTextWrapper,
    renderCardPercentageWrapper,
    renderCardInfoWrapper,
    renderTextWrapperHeader,
    renderTextNameWrapper,
    renderTextidsWrapper,
    renderTitleInfoWrapperHealthcheck,
    renderTitleInfoWrapperBuybox,
    renderTitleInfoWrapperHealthBuybox,
    renderFlexWrapper,
    renderStatusContainer,
    renderTitleInfoWrapperInformation,
    renderCardInfoLogoWrapper,
    renderUnitWrapper,
    setCopyResultText,
    setCopyCreationResult,
    callerComponent = 'PublicationList',
    setSearchView,
    setSelectedPublication,
    setLoadingCompetition
}) => {
    const [modalToggle, setToggle] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isChecked, setIsChecked] = useState(checked);

    const handleToggleModal = () => {
        if(callerComponent === 'SearchPublications'){
            setSelectedPublication(product);
            setSearchView(false);
            setLoadingCompetition(true);
        }else{
            setToggle(!modalToggle);
        }        
    };

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        if (deselectedIds.includes(product.id_publication)) {
            setIsChecked(false);
        } else {
            setIsChecked(checked);
        }
    }, [checked, deselectedIds, product.id_publication]);

    const handleCheckboxChange = (isChecked) => {
        setIsChecked(isChecked);

        if (!isChecked && selectAllPagesChecked) {
            setDeselectedIds((prevIds) => [...prevIds, product.id_publication]);
        } else if (isChecked) {
            setDeselectedIds((prevIds) =>
                prevIds.filter((id) => id !== product.id_publication)
            );
        }

        onCheckboxChange(product.id_publication, product.client_id, isChecked);
    };

    const handleBellClick = (event) => {
        event.stopPropagation();
    };

    const handleRedirectClick = (event) => {
        event.stopPropagation();
        const url = product?.publication_url;
        if (url) {
            window.open(url, '_blank');
        }
    };

    const toSpanishNumberFormat = (number) => {
        let numStr = number.toString().replace(/\D/g, '');
        let parts = numStr.split('.');
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? ',' + parts[1] : '';
        let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return formattedInteger + decimalPart;
    }

    const transformTranslatedStatus = (status) => {
        if (status === 'Rechazado') {
            return 'Rechazada';
        }
        if (status === 'Publicado') {
            return 'Publicada';
        }
        return status;
    };

    const statusData = {
        status: product?.status,
        translatedStatus: transformTranslatedStatus(product?.translated_status),
        caller: 'publications',
    };

    const alertsForProduct = alertSByPublication?.filter(
        (alert) => alert.id_publication === product.id_publication
    );

    const handleCopyPublicationId = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(product.id_publication);
    };

    const handleCopyPublicationEan = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(product.ean);
    };

    const handleCopyToClipboard = (text = '') => {
        navigator.clipboard.writeText(text);
        setCopyResultText("1, 2, 3 ¡Código copiado!");
        setCopyCreationResult(true); 
    };

    const getCardCheckContainerClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? cardCheckContainer || classes.cardCheckContainer
            : firstcardCheckContainer || classes.firstcardCheckContainer;
    }; 
    
    const getCardTooltipContainerParentClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? classes.cardTooltipContainerParent
            : classes.cardNoTooltipContainerParent;
    }; 

    const getCardCheckboxClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? classes.cardCheckbox
            : classes.cardCheckboxMiddle;
    }; 

    const getBellIconClass = () => {
        return alertSByPublication?.find(
            (publication) => publication.id_publication === product.id_publication
        )
            ? classes.bellIcon
            : classes.bellIconInvisible;
    }; 

    const tooltipContentBell = `
        <div style="width: 273px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #57c2bd; font-family: Montserrat;">
            <p style="margin: 0; font-weight: 300; color: #000;">Esta publicación tiene estas alertas:</p>
            <p style="margin: 0; font-weight: 300; color: #000;">&nbsp;</p>
            ${alertsForProduct
                ?.map(
                    (alert) => `<p style="margin: 0; font-weight: 500;">${alert.alert_name}</p>`
                )
                .join('')}
        </div>
    `;

    const tooltipHealthcheckContent = `
    <div style="position: relative; display: flex; width: 315px;">
        <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
            <p style="margin: 0;">
                <span>
                    <span style="font-weight: 500;">
                        ¡Esta es la calidad de tu publicación!
                    </span>
                    <br>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        Es fundamental para tener un mejor posicionamiento... Te desafío a llegar al 100%, no creo que sea tan difícil no?
                    </span>                
                </span>
            </p>
        </div>
    </div>`;

    const tooltipBuyboxContent = `
    <div style="position: relative; display: flex; width: 315px;">
        <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
            <p style="margin: 0;">
                <span>
                    <span style="font-weight: 500;">
                        BuyBox solo tiene 3 estados:
                    </span>
                    <br>
                    <br>
                    <span style="font-weight: 500;">
                        Ganando:
                    </span>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        Tu publicación aparece sobre la de tu competencia.
                    </span>  
                    <br>
                    <span style="font-weight: 500;">
                        Empatando:
                    </span>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        Tu publicación puede o no aparecer antes que la de tu competencia.
                    </span>  
                    <br>   
                    <span style="font-weight: 500;">
                        Perdiendo:
                    </span>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        La publicación de tu competencia está antes que la tuya...
                    </span>  
                    <br>  
                    <br>  
                    <span style="font-weight: 500;">
                        ¡No te dejes ganar!
                    </span>         
                </span>
            </p>
        </div>
    </div>`;

    const tooltipInformationContent = `
    <div style="position: relative; display: flex; width: 300px;">
        <div style="width: 250px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
            <p style="margin: 0;">
                <span>
                    <span style="font-weight: 500;">
                        Aquí te traemos la información general de tu publicación...
                    </span>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        en el canal que está publicada y el stock disponible de la publicación
                    </span>                
                </span>
            </p>
        </div>
    </div>`;

    
    return (
        <>
            <div
                className={`${cardWrapper || classes.cardWrapper} ${isChecked ? checkedCard || classes.checkedCard : ''}`}
                onClick={handleToggleModal}
            >
                <div className={getCardCheckContainerClass()}>
                    {renderCardCheckContainer ? (
                        renderCardCheckContainer(product)
                    ) : (
                        <>
                            <div className={getCardTooltipContainerParentClass()}>
                                <div className={classes.cardTooltipContainer}>
                                    <AlertTooltip tooltipContent={tooltipContentBell}>
                                        <img
                                            className={getBellIconClass()}
                                            src={Bell}
                                            alt="Alert Bell"
                                            onClick={handleBellClick}
                                        />
                                    </AlertTooltip>
                                </div>
                            </div>
                            <div className={getCardCheckboxClass()}>
                                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                            </div>
                        </>
                    )}
                </div>
                <div className={innercardWrapper || classes.innercardWrapper}>
                    <div className={leftSideContainer || classes.leftSideContainer}>
                        <div className={cardImgWrapper || classes.cardImgWrapper}>
                            {renderCardImgWrapper ? (
                                renderCardImgWrapper(product)
                            ) : (
                                <img
                                    width={windowWidth < 1300 ? '80%' : '100%'}
                                    className={cardImg || classes.cardImg}
                                    src={product.images ? product.images[0]?.link ?? '' : ''}
                                    alt={product.name}
                                />
                            )}
                        </div>
                        <div className={cardTextWrapper || classes.cardTextWrapper}>
                            {renderCardTextWrapper ? (
                                renderCardTextWrapper(product)
                            ) : (
                                <>
                                    <div className={textWrapperHeader || classes.textWrapperHeader}>
                                        {renderTextWrapperHeader ? (
                                            renderTextWrapperHeader(product)
                                        ) : (
                                            <>
                                                <div className={classes.textClientWrapper}>
                                                    <span className={classes.textClient}>{product.client}</span>
                                                </div>
                                                <div className={classes.textCategoryWrapper}>
                                                    <span className={classes.textTitle}>{product.category}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className={textNameWrapper || classes.textNameWrapper}>
                                        {renderTextNameWrapper ? (
                                            renderTextNameWrapper(product)
                                        ) : (
                                            <span className={textName || classes.textName}>{product.name}</span>
                                        )}
                                    </div>
                                    <div className={textidsWrapper || classes.textidsWrapper}>
                                        {renderTextidsWrapper ? (
                                            renderTextidsWrapper(product)
                                        ) : (
                                            <>
                                                <div className={classes.textIdMLC}>
                                                    <span className={classes.textId}>ID: {product.id_publication}</span>
                                                    <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationId} />
                                                </div>
                                                <div className={classes.textIdEAN}>
                                                    <span className={classes.textId}>EAN: {product.ean}</span>
                                                    <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationEan} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={rightSideContainer || classes.rightSideContainer}>
                        <div className={cardPercentageWrapper || classes.cardPercentageWrapper}>
                            {renderCardPercentageWrapper ? (
                                renderCardPercentageWrapper(product)
                            ) : (
                                <>
                                    {product.has_healthcheck && !product.has_buybox && (
                                        <>
                                            <div className={titleInfoWrapperHealthcheck || classes.titleInfoWrapperHealthcheck}>
                                                {renderTitleInfoWrapperHealthcheck ? (
                                                    renderTitleInfoWrapperHealthcheck(product)
                                                ) : (
                                                    <>
                                                        <span className={titleInfo || classes.titleInfo}>
                                                            Healthcheck
                                                            <AlertTooltip tooltipContent={tooltipHealthcheckContent}>
                                                                <img
                                                                    className={classes.infoIconForTootltipInfo}
                                                                    alt="info"
                                                                    src={infoFortooltipIcon}
                                                                />
                                                            </AlertTooltip>
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                            <div className={flexWrapper || classes.flexWrapper}>
                                                {renderFlexWrapper ? (
                                                    renderFlexWrapper(product)
                                                ) : (
                                                    <div className={percentageWrapper || classes.percentageWrapper}>
                                                        <ProgressBar percentage={(product.healthcheck?.health || 0) * 100} strokeWidth={10} isDefaultColor={true} defaultFontSize={'24px'} defaultFontWeight={'700'} />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {product.has_buybox && (
                                        <>
                                            <div className={titleInfoWrapperBuybox || classes.titleInfoWrapperBuybox}>
                                                {renderTitleInfoWrapperBuybox ? (
                                                    renderTitleInfoWrapperBuybox(product)
                                                ) : (
                                                    <span className={titleInfo || classes.titleInfo}>
                                                        Buybox
                                                        <AlertTooltip tooltipContent={tooltipBuyboxContent}>
                                                            <img
                                                                className={classes.infoIconForTootltipInfo}
                                                                alt="info"
                                                                src={infoFortooltipIcon}
                                                            />
                                                        </AlertTooltip>
                                                    </span>
                                                )}
                                            </div>
                                            <div className={flexWrapper || classes.flexWrapper}>
                                                {renderFlexWrapper ? (
                                                    renderFlexWrapper(product)
                                                ) : (
                                                    <div className={classes.buyboxWrapper}>
                                                        <div>
                                                            {['winning'].includes(product.buybox.status) && (
                                                                <img className={classes.imgBuyBox} src={Ganando} alt="Winning" />
                                                            )}
                                                            {!['winning', 'sharing_first_place'].includes(product.buybox.status) && (
                                                                <img className={classes.imgBuyBox} src={Perdiendo} alt="Losing" />
                                                            )}
                                                            {['sharing_first_place'].includes(product.buybox.status) && (
                                                                <img className={classes.imgBuyBox} src={Empatando} alt="Tying" />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {!product.has_buybox && !product.has_healthcheck && (
                                        <>
                                            <div className={titleInfoWrapperHealthBuybox || classes.titleInfoWrapperHealthBuybox}>
                                                {renderTitleInfoWrapperHealthBuybox ? (
                                                    renderTitleInfoWrapperHealthBuybox(product)
                                                ) : (
                                                    <span className={titleInfoHealthBuybox || classes.titleInfoHealthBuybox}>Healthcheck y buybox</span>
                                                )}
                                            </div>
                                            <div className={flexWrapper || classes.flexWrapper}>
                                                {renderFlexWrapper ? (
                                                    renderFlexWrapper(product)
                                                ) : (
                                                    <EmptyPublications calledFrom="publicationsList" />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className={statusContainer || classes.statusContainer}>
                                        {renderStatusContainer ? (
                                            renderStatusContainer(product)
                                        ) : (
                                            <PublicationStatus statusData={statusData} />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={cardInfoWrapper || classes.cardInfoWrapper}>
                            {renderCardInfoWrapper ? (
                                renderCardInfoWrapper(product)
                            ) : (
                                <>
                                    <div className={titleInfoWrapperInformation || classes.titleInfoWrapperInformation}>
                                        {renderTitleInfoWrapperInformation ? (
                                            renderTitleInfoWrapperInformation(product)
                                        ) : (
                                            <span className={titleInfo || classes.titleInfo}>
                                                Información
                                                <AlertTooltip tooltipContent={tooltipInformationContent}>
                                                    <img
                                                        className={classes.infoIconForTootltipInfo}
                                                        alt="info"
                                                        src={infoFortooltipIcon}
                                                    />
                                                </AlertTooltip>
                                            </span>
                                        )}
                                    </div>
                                    <div className={cardInfoLogoWrapper || classes.cardInfoLogoWrapper}>
                                        {renderCardInfoLogoWrapper ? (
                                            renderCardInfoLogoWrapper(product)
                                        ) : (
                                            <>
                                                <div>
                                                    <img
                                                        className={classes.channelIcon}
                                                        src={product.channel_icon}
                                                        alt="Channel Icon"
                                                    />
                                                </div>
                                                <div className={classes.cardInfoRedirectIcon}>
                                                    <img src={Redirect} alt="Redirect Icon" onClick={handleRedirectClick} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className={unitWrapper || classes.unitWrapper}>
                                        {renderUnitWrapper ? (
                                            renderUnitWrapper(product)
                                        ) : (
                                            <>
                                                <div className={classes.unitWrapperIcon}>
                                                    <img className={classes.unitBasketIcon} src={Canasta} alt="Canasta" />
                                                </div>
                                                <div className={classes.unitWrapperText}>
                                                    <span className={classes.unitStockText}>
                                                        {toSpanishNumberFormat(product.stock) ?? 0} Unidades
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {modalToggle && (
                <PublicationModal
                    product={product}
                    modalToggle={modalToggle}
                    handleToggleModal={handleToggleModal}
                    modalPosition={modalPosition}
                    alertsForProduct={alertsForProduct}
                />
            )}
        </>
    );
};

PublicationCard.propTypes = {
    product: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    onCheckboxChange: PropTypes.func,
    deselectedIds: PropTypes.array,
    setDeselectedIds: PropTypes.func,
    selectAllPagesChecked: PropTypes.bool,
    alertSByPublication: PropTypes.array,
    innercardWrapper: PropTypes.string,
    leftSideContainer: PropTypes.string,
    rightSideContainer: PropTypes.string,
    cardWrapper: PropTypes.string,
    checkedCard: PropTypes.string,
    cardCheckContainer: PropTypes.string,
    cardImgWrapper: PropTypes.string,
    cardImg: PropTypes.string,
    cardTextWrapper: PropTypes.string,
    cardPercentageWrapper: PropTypes.string,
    percentageWrapper: PropTypes.string,
    cardInfoWrapper: PropTypes.string,
    textWrapperHeader: PropTypes.string,
    textNameWrapper: PropTypes.string,
    textName: PropTypes.string,
    textidsWrapper: PropTypes.string,
    titleInfoWrapperHealthcheck: PropTypes.string,
    titleInfoWrapperBuybox: PropTypes.string,
    titleInfoWrapperHealthBuybox: PropTypes.string,
    titleInfo: PropTypes.string,
    titleInfoHealthBuybox: PropTypes.string,
    flexWrapper: PropTypes.string,
    buyboxWrapper: PropTypes.string,
    statusContainer: PropTypes.string,
    titleInfoWrapperInformation: PropTypes.string,
    cardInfoLogoWrapper: PropTypes.string,
    unitWrapper: PropTypes.string,
    firstcardCheckContainer: PropTypes.string,
    renderCardCheckContainer: PropTypes.func,
    renderCardImgWrapper: PropTypes.func,
    renderCardTextWrapper: PropTypes.func,
    renderCardPercentageWrapper: PropTypes.func,
    renderCardInfoWrapper: PropTypes.func,
    renderTextWrapperHeader: PropTypes.func,
    renderTextNameWrapper: PropTypes.func,
    renderTextidsWrapper: PropTypes.func,
    renderTitleInfoWrapperHealthcheck: PropTypes.func,
    renderTitleInfoWrapperBuybox: PropTypes.func,
    renderTitleInfoWrapperHealthBuybox: PropTypes.func,
    renderFlexWrapper: PropTypes.func,
    renderBuyboxWrapper: PropTypes.func,
    renderStatusContainer: PropTypes.func,
    renderTitleInfoWrapperInformation: PropTypes.func,
    renderCardInfoLogoWrapper: PropTypes.func,
    renderUnitWrapper: PropTypes.func,
};

export default PublicationCard;
