const configFile = require("./../config/url");

export const thousandSeparator = (num, separator) => {
    if (!num)
        return num

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const excelFormater = (products) => {
    let item_error;

    try {
        console.log(products);
        const response = [];

        for (const item of products) {
            item_error = item;
            let publication = {
                identificador: item.id_publication,
                nombre: item.name,
                marca: item.brand,
                categoria: item.category,
                canal: item.channel,
                cliente: item.client,
                tipo_de_moneda: item.currency,
                precio: item.price,
                ean: item.ean,
                sku: item.sku,
                url_publicacion: item.publication_url,
                tipo_de_logistica: item.logistica,
            }

            if (item.products[0] && item.products[0]?.stock_wms[0]) {
                publication.stock = item.products[0].stock_wms[0].stock;
                publication.bodega = item.products[0].stock_wms[0].bodega;
                publication.descripcion_larga = item.products[0]?.long_description ?? "";
                publication.descripcion_corta = item.products[0]?.short_description ?? "";
            }

            if (item.has_buybox) {
                publication.buybox_current_price = item.buybox.current_price;
                publication.buybox_price_to_win = item.buybox.price_to_win;
                publication.buybox_status = item.buybox.status;
            }

            if (item.has_healthcheck) {
                publication.healthcheck = item.healthcheck.health;
            }

            if (!item.has_buybox && !item.has_healthcheck && item.products[0]) {
                publication.score = item.products[0].publication_score.total;
            }

            response.push(publication);
        }

        return response;
    } catch (error) {
        console.log(item_error);
        console.log(error);
    }
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


export function mapServicesNewClientToDataKey(clientData){
    return {
        customerService: clientData?.servicio || false,
        salesChannelsCreation: clientData?.canales || false,
        warehouseStorageInstance: clientData?.bodega || false,
        billing: clientData?.facturacion || false
    }
}

export function emailValidation (email) {
    // expresion regular para validar email
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
}; 
function sendMetric(metric, accessToken){
    try{
        const myHeaders = new Headers();
        myHeaders.append("x-api-key", configFile.config.apiKeyQueues);
        myHeaders.append(
            "Authorization",
            `Bearer ${accessToken}`
        );
        myHeaders.append(
            "Access-Control-Allow-Headers",
            "*"
        );
        myHeaders.append("Content-Type", "application/json");

        const options = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify(metric)
        };

        fetch(
            `${configFile.config.ambienteQueues}metric`,
            options
        ).then(()=> console.log("metric sent")
        ).catch((err)=> console.log(`metric failed ${JSON.stringify(err)}`));
    } catch (error) {
        console.log("failed sending metric");
    }
}

export function performanceMetric(endpoint, method, api, user, time, accessToken){
    try {
        const date = new Date();
        const metric = {
            type: "performance",
            data: {
                endpoint,
                method,
                api,
                time,
                user,
                date: date.toISOString(),
            }
        };
        return sendMetric(metric, accessToken);
    } catch (error) {
        console.log("failed creating metric");
    }
}

export function clickMetric(elementId, user, accessToken){
    try {
        const date = new Date();
        const metric = {
            type: "click",
            data: {
                elementId,
                user,
                date: date.toISOString(),
            }
        };
        return sendMetric(metric, accessToken);
    } catch (error) {
        console.log("failed creating metric");
    }
}

export function behaviourMetric(view, activity, time, user, accessToken){
    try {
        const date = new Date();
        const metric = {
            type: "behaviour",
            data: {
                view,
                activity,
                time,
                user,
                date: date.toISOString(),
            }
        };
        return sendMetric(metric, accessToken);
    } catch (error) {
        console.log("failed creating metric");
    }
}

export function isObject(obj) {	
    return typeof obj === 'object' && obj !== null && ! Array.isArray(obj)
}

export function translateLogisticType(logisticType){
    try {
        const translationsMap = {
            "cross_docking": "Colecta",
            "custom": "Custom",
            "drop_off": "Places",
            "xd_drop_off": "Places",
            "self_service": "Flex",
            "fulfillment": "Fulfillment",
            "not_specified": "No Especificada"
        }


        return translationsMap[logisticType] || logisticType

    } catch (error) {
        return logisticType
    }
}


export function removeAccentsFromString(stringToRemoveAccentsFrom) {
    try {
        return stringToRemoveAccentsFrom?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") || stringToRemoveAccentsFrom
    } catch (error) {
        console.log(error)
        return stringToRemoveAccentsFrom
    }
}