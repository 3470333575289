import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Grid,
    Skeleton,
    IconButton
} from "@mui/material";
import ArrowSelect from "../../../../assets/img/icons/selectArrowBlack.png";
import whiteArrow from "../../../../assets/img/icons/whiteArrow.png";
import selectedCheck from "../../../../assets/img/icons/selectedCheck.png"
import notSelected from "../../../../assets/img/icons/notSelected.png"
import ResetFilters from "../../../../assets/img/icons/deleteFilters.svg"
import DatePicker from '../../../../components/UI/DatePicker';


const FiltersMetricsDashboards = ({ filtersData, userDashboardsData, dashboardGroupSelected, loaders, setters, originalFiltersData, handlers }) => {
    const [country, setCountry] = useState("");
    const [store, setStore] = useState([]);
    const [channel, setChannel] = useState([]);
    const [dashboardGroup, setDashboardGroup] = useState("")
    const [dateRange, setDateRange] = useState({
        startDateAsObject: null,
        endDateAsObject: null,
        startDateAsString: "",
        endDateAsString: ""
    })


    const [currentFiltersValues, setCurrentFiltersValues] = useState([]);
    const debounceTimeout = useRef(null);
    const isFirstRender = useRef(true);
    const defaultValuesSet = useRef(false)

    //datePicker
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [firstClick, setFirstClick] = useState(true);
    const [isDatePickerSelectOpen, setIsDatePickerSelectOpen] = useState(false)

    const setDefaultValuesFilters = (originalFiltersData) => {
        const countryPriority = [1,2,4,3,5]
        let updatedFiltersValues = [ ...currentFiltersValues ];

        for(const countryId of countryPriority){
            const countryFound = originalFiltersData.countries?.find(e => e.id == countryId)
            if(countryFound){
                setCountry(countryFound.name)
        
                updatedFiltersValues = removeElementFromCurrentFiltersByName("pais", updatedFiltersValues)

                const channels = originalFiltersData.channels?.filter(e => e.countries?.includes(countryFound.id))?.map(e => e.name)
                const stores = originalFiltersData.stores?.filter(e => e.countries?.includes(countryFound.id))?.map(e => e.name)
                
                setStore([])
                setChannel([])
                
                updatedFiltersValues = [ 
                    ...updatedFiltersValues, 
                    { Name: "pais", Values: [countryFound.name] },
                    { Name: "cliente", Values: stores },
                    { Name: "canalVenta", Values: channels }
                ]

               
                const currentDate = new Date();
                const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

                const startDateAsString = firstDayOfMonth.toISOString().substring(0, 10)
                const endDateAsString = currentDate.toISOString().substring(0, 10)

                updatedFiltersValues = [ 
                    ...updatedFiltersValues, 
                    ...[
                        { Name: "fechaInicio", Values: [startDateAsString] },
                        { Name: "fechaFin", Values: [endDateAsString] }
                    ],

                ]
                
                setDateRange({
                    startDateAsObject: firstDayOfMonth,
                    endDateAsObject: currentDate,
                    startDateAsString,
                    endDateAsString
                })

                setStartDate(firstDayOfMonth)
                setEndDate(currentDate)

                setDashboardGroup(dashboardGroupSelected?.group?.id)
                break
            }
        }
        
        setFiltersDataAndValuesUpdated(updatedFiltersValues)
    }

    const removeElementFromCurrentFiltersByName = (name, filtersArray) => {
        try {
            filtersArray = filtersArray?.filter(e => e.Name !== name) || []
            
            return filtersArray
        } catch (error) {
            console.log(error)
            return filtersArray
        }
    }

    const getSetFilterValuesFunctions = (attributeName) => {
        try {
            let parametersMap = {
                "country": {
                    setterFunc: setCountry,
                    keyQuickSightParams: "pais"
                },
                "store": {
                    setterFunc: setStore,
                    keyQuickSightParams: "cliente"
                },
                "channel": {
                    setterFunc: setChannel,
                    keyQuickSightParams: "canalVenta"
                },
                "dashboardGroup": {
                    setterFunc: setDashboardGroup,
                    keyQuickSightParams: null
                }
            }
            
            return parametersMap[attributeName] || null
        } catch (error) {
            console.log(error)
            return null
        }
    }

    const setFiltersDataAndValuesUpdated = (updatedFiltersValues, currentFilterName, currentFilterValue) => {
        try {
            
            let filtersDataUpdated = JSON.parse(JSON.stringify(originalFiltersData))

            const countryFilterFound = updatedFiltersValues?.find(e => e.Name === "pais")

            let countryParameterFoundInfiltersData = null
            if(countryFilterFound)
                countryParameterFoundInfiltersData = filtersDataUpdated?.countries?.find(e => countryFilterFound.Values?.includes(e.name))

            if(currentFilterName === "country"){
                setChannel([])
                setStore([])
                updatedFiltersValues = updatedFiltersValues.filter(e => e.Name === "pais" || e.Name === "fechaInicio" || e.Name === "fechaFin")
            }
            let storesInFiltersValues = null
            if(currentFilterName === "channel" || currentFilterName === "store")
                storesInFiltersValues = updatedFiltersValues?.find(e => e.Name === "cliente")

            if(currentFilterName === "channel"){
                //set values in case there's no channel selected (all channels of the current country are selected)
                if(!currentFilterValue?.length && countryFilterFound){
                    
                    if(countryParameterFoundInfiltersData){
                        const channelsFilters = updatedFiltersValues.find(e => e.Name === "canalVenta")
                        const channelsFromCountry = originalFiltersData.channels?.filter(e => e.countries?.includes(countryParameterFoundInfiltersData.id))?.map(e => e.name) || ["-1"]
                        
                        if(channelsFilters){
                            channelsFilters.Values = channelsFromCountry
                        }
                        else{
                            updatedFiltersValues.push({
                                Name: "canalVenta",
                                Values: channelsFromCountry
                            })
                        }

                        if(storesInFiltersValues){
                            storesInFiltersValues.Values = originalFiltersData.stores?.filter(e => {
                                if(store?.length){
                                    return store?.includes(e.name)
                                }
                                else{
                                    return e.countries?.includes(countryParameterFoundInfiltersData.id)
                                }
                            }).map(e => e.name)
                        }
                    }
                }

                if(storesInFiltersValues){
                    const channelsIdsSelected = originalFiltersData.channels.filter(e => {
                        return updatedFiltersValues?.find(e => e.Name === "canalVenta")?.Values?.includes(e.name)
                    })?.map(e => e.id) || []
                    
                    const currentSelectedStores = originalFiltersData.stores?.filter(store => {
                        return storesInFiltersValues?.Values?.includes(store?.name) && store?.channels.find(channelId => {
                            return channelsIdsSelected?.includes(channelId)
                        })
                    })
                    
                    let currentSelectedStoresNames = currentSelectedStores?.map(e => e.name)
                    storesInFiltersValues.Values = currentSelectedStoresNames

                    if(!storesInFiltersValues.Values?.length){
                        currentSelectedStoresNames = originalFiltersData.stores?.filter(store => store?.channels.find(channelId => {
                            return channelsIdsSelected?.includes(channelId)
                        })).map(e => e.name)
                        storesInFiltersValues.Values = currentSelectedStoresNames

                        console.log(storesInFiltersValues)
                    }

                    if(store?.length && currentFilterName !== "country")
                        setStore(currentSelectedStoresNames)
                }

                
            }
            
            if(countryFilterFound){ 
                if(countryParameterFoundInfiltersData){
                    filtersDataUpdated.channels = filtersDataUpdated?.channels?.filter( e => e.countries?.includes(countryParameterFoundInfiltersData.id)) || []
                    filtersDataUpdated.stores = filtersDataUpdated?.stores?.filter( e => e.countries?.includes(countryParameterFoundInfiltersData.id)) || []
                    if(currentFilterName === "country"){
                        updatedFiltersValues.push({
                            Name: "cliente",
                            Values: filtersDataUpdated.stores?.map(e => e.name) || []
                        })
                        updatedFiltersValues.push({
                            Name: "canalVenta",
                            Values: filtersDataUpdated.channels?.map(e => e.name) || []
                        })
                    }
                }
            }

            const channelFilterFound = updatedFiltersValues?.find(e => e.Name === "canalVenta")
            
            if(channelFilterFound){
                const channelParametersFoundInfiltersData = filtersDataUpdated?.channels?.filter(e => channelFilterFound.Values?.includes(e.name)) || []
                if(channelParametersFoundInfiltersData?.length){
                    filtersDataUpdated.stores = filtersDataUpdated.stores?.filter( store => store.channels?.find( channelId => channelParametersFoundInfiltersData.map(channelObject => channelObject.id)?.includes(channelId)) != null ) || []
                    
                }
            }

            if(currentFilterName === "store"){
                //set values in case there's no channel selected (all channels of the current country are selected)
                if(!currentFilterValue?.length && countryFilterFound && channelFilterFound){
                    const channelsSelected = channelFilterFound?.Values

                    if(countryParameterFoundInfiltersData && channelsSelected?.length){
                        const channelIdsSelected = originalFiltersData.channels.filter(e => {
                            return channelsSelected?.includes(e.name)
                        })?.map(e => e.id) || []

                        const storesFromChannelsAndCountry = originalFiltersData.stores?.filter(currentStore => {
                            return currentStore?.countries?.includes(countryParameterFoundInfiltersData.id) && (currentStore?.channels?.find( channelInCurrentStore => channelIdsSelected?.includes(channelInCurrentStore)) != undefined)
                        })?.map(e => e.name) || ["-1"]
                        
                        if(storesInFiltersValues){
                            storesInFiltersValues.Values = storesFromChannelsAndCountry
                        }
                        else{
                            updatedFiltersValues.push({
                                Name: "cliente",
                                Values: storesFromChannelsAndCountry
                            })
                        }
                    }
                }
            }
            
            setters.setFiltersData(filtersDataUpdated)
            setCurrentFiltersValues([...updatedFiltersValues])
            console.log(updatedFiltersValues)
        } catch (error) {
            console.log(error)
        }
    }

    const manageSetDateRangeForFiltersValues = (value, updatedFiltersValues) => {
        try {
            console.log("manageSetDateRangeForFiltersValues", value)
            
            updatedFiltersValues = removeElementFromCurrentFiltersByName("fechaInicio", updatedFiltersValues)
            updatedFiltersValues = removeElementFromCurrentFiltersByName("fechaFin", updatedFiltersValues)
            updatedFiltersValues = [ 
                ...updatedFiltersValues, 
                ...[
                    {Name: "fechaInicio", Values: [value.startDate]},
                    {Name: "fechaFin", Values: [value.endDate || value.startDate]}
                ]
            ]

            return updatedFiltersValues
        } catch (error) {
            console.log(error)
            return updatedFiltersValues
        }
    }

    const manageDashboardGroupChange = (groupId) => {
        try {
            handlers.handleDashboardGroupChange(groupId)
            
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeFilter = (event) => {
        try {
            let { name, value } = event.target;

            let updatedFiltersValues = [ ...currentFiltersValues ];
            
            //get set functions and quicksight keys for filters values from select components, EXCEPT DATE
            const setFiltersValuesFunc = getSetFilterValuesFunctions(name)
            
            if(!setFiltersValuesFunc && name !== "date")
                return
            
            if(setFiltersValuesFunc?.setterFunc)
                setFiltersValuesFunc.setterFunc(value)

            if(name === "country")
                value = [value]

            if(name === "dashboardGroup"){
                manageDashboardGroupChange(value)
                return
            }

            //manage date separately, since its not set from select component
            if(name === "date"){
                updatedFiltersValues = manageSetDateRangeForFiltersValues(value, updatedFiltersValues)
            }

            if(setFiltersValuesFunc?.keyQuickSightParams){
                updatedFiltersValues = removeElementFromCurrentFiltersByName(setFiltersValuesFunc.keyQuickSightParams, updatedFiltersValues)
                updatedFiltersValues = [ ...updatedFiltersValues, ...[{Name: setFiltersValuesFunc.keyQuickSightParams, Values: value}] ]
            }
            
            console.log("---------------------------------------------------------------")
            console.log("FILTERS VALUES UPDATED", updatedFiltersValues)
            console.log("---------------------------------------------------------------")
            
            setFiltersDataAndValuesUpdated(updatedFiltersValues, name, value)
        } catch (error) {
            console.log(error)
        }
    };
    
    const isAnyFilterActive = () => {
        return country || store.length > 0 || channel.length > 0 || dashboardGroup;
    };

    const resetFilters = async () => {
        try {
            setDefaultValuesFilters(originalFiltersData)
        } catch (error) {
            console.log(error)
        }
    }
    
    //date picker
    const handleDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleCloseFromButton = () => {
        try {
            setShowDatePicker(false)
            setDateRange({
                startDateAsObject: startDate,
                endDateAsObject: endDate,
                startDateAsString: startDate?.toISOString().substring(0, 10) || null,
                endDateAsString: endDate?.toISOString().substring(0, 10) || null
            })

            handleChangeFilter(
                {
                    target: {
                        name: "date",
                        value: { 
                            startDate: startDate?.toISOString().substring(0, 10) || null,
                            endDate: endDate?.toISOString().substring(0, 10) || null
                        }
                    }
                }
            )
        } catch (error) {
            console.log(error)
        }

    };

    const handlecloseNotFromButton = () => {
        setStartDate(dateRange.startDateAsObject)
        setEndDate(dateRange.endDateAsObject)
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return;
        }
       
        setters.setFiltersValuesForDashboards(currentFiltersValues)
       
    }, [currentFiltersValues]);

    useEffect(() => {
        if(Object.keys(originalFiltersData)?.length && !defaultValuesSet?.current){
            setDefaultValuesFilters(originalFiltersData)
            defaultValuesSet.current = true
        }
    }, [originalFiltersData])

    useEffect(() => {
        if(dashboardGroupSelected != null){
            setDashboardGroup(dashboardGroupSelected?.group?.id)
        }
    }, [dashboardGroupSelected])
    

    return (
        <>
            <Grid container sx={{
                '& > :not(style)': { my: "1px", mr: "5px", ml: "5px" }, display: "flex", justifyContent: 'flex-end', alignItems: 'center',
            }}>
                <Grid item xs={4} lg={2} md={2} xl={3}>
                {loaders.isLoadingFilters ?
                    <Skeleton width="100%" height="30px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                :
                    <Select
                        sx={{  height: "30", backgroundColor: dashboardGroup && "#57C2BD" }}
                        name="dashboardGroup"
                        labelId="dashboardGroup"
                        variant="standard"
                        id="dashboardGroup"
                        value={dashboardGroup}
                        onChange={handleChangeFilter}
                        displayEmpty
                        renderValue={() => { return <span style={{ color: dashboardGroup && "white" }}>Dashboard</span> }}
                        IconComponent={() => (
                            <div style={{ position: 'absolute', right: 15, pointerEvents: 'none', cursor: 'pointer' }}>
                                <img src={dashboardGroup ? whiteArrow : ArrowSelect} alt="arrow" />
                            </div>
                        )}
                    >
                        {userDashboardsData?.map((filter, index) => (
                            <MenuItem
                                key={filter.group?.name}
                                value={filter.group?.id}
                                sx={{
                                    '&:hover': { backgroundColor: 'transparent' },
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent !important',
                                    borderBottom: index < userDashboardsData.length - 1 ? '2px solid #D3D3D3' : 'none'
                                }}
                            >
                                <ListItemText primary={dashboardGroup == filter.group.id ? <b>{filter.group.name}</b> : filter.group.name} />
                            </MenuItem>
                        ))}
                    </Select>
                }
                </Grid>
                <Grid item xs={4} lg={1.3} md={1.5} xl={0.9}>
                {loaders.isLoadingFilters ?
                    <Skeleton width="100%" height="30px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                :
                    <Select
                        sx={{  height: "30", backgroundColor: country && "#57C2BD" }}
                        name="country"
                        labelId="country"
                        variant="standard"
                        id="country"
                        value={country}
                        onChange={handleChangeFilter}
                        displayEmpty
                        renderValue={() => { return <span style={{ color: country && "white" }}>País</span> }}
                        IconComponent={() => (
                            <div style={{ position: 'absolute', right: 15, pointerEvents: 'none', cursor: 'pointer' }}>
                                <img src={country ? whiteArrow : ArrowSelect} alt="arrow" />
                            </div>
                        )}
                    >
                        {filtersData?.countries?.map((filter, index) => (
                            <MenuItem
                                key={filter.name}
                                value={filter.name}
                                sx={{
                                    '&:hover': { backgroundColor: 'transparent' },
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent !important',
                                    borderBottom: index < filtersData.countries.length - 1 ? '2px solid #D3D3D3' : 'none'
                                }}
                            >
                                <ListItemText primary={country ==filter.name ? <b>{filter.name}</b> : filter.name} />
                            </MenuItem>
                        ))}
                    </Select>
                }

                </Grid>
                <Grid item xs={9} lg={2.4} md={2.4} xl={1.6}>
                {loaders.isLoadingFilters ?
                    <Skeleton width="100%" height="30px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                :
                    <Select
                        multiple
                        sx={{  height: "30", backgroundColor: channel.length > 0 && "#57C2BD" }}
                        name="channel"
                        labelId="channel"
                        variant="standard"
                        id="channel"
                        value={channel}
                        onChange={handleChangeFilter}
                        displayEmpty
                        renderValue={() => { return <span style={{ color: channel.length > 0 && "white" }}>Canal de venta</span> }}
                        IconComponent={() => (
                            <div style={{ position: 'absolute', right: 15, pointerEvents: 'none', cursor: 'pointer' }}>
                                <img src={channel.length > 0 ? whiteArrow : ArrowSelect} alt="arrow" />
                            </div>
                        )}
                    >
                        {filtersData?.channels?.map((filter, index) => (
                            <MenuItem
                                key={filter.name}
                                value={filter.name}
                                sx={{
                                    '&:hover': { backgroundColor: 'transparent' },
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent !important',
                                    borderBottom: index < filtersData?.channels?.length - 1 ? '2px solid #D3D3D3' : 'none'
                                }}
                            >
                                <Checkbox
                                    icon={<img src={notSelected} />}
                                    checkedIcon={<img src={selectedCheck} />}
                                    checked={channel.indexOf(filter.name) > -1}
                                    style={{ color: "#D3D3D3" }}

                                    sx={{
                                        '&:hover': { backgroundColor: 'transparent' },
                                        '&.Mui-checked': { backgroundColor: 'transparent' },
                                    }}
                                />
                                <ListItemText primary={channel.includes(filter.name) ? <b>{filter.name}</b> : filter.name} />
                            </MenuItem>
                        ))}
                    </Select>
                }
                </Grid>
                <Grid item xs={6} lg={1.4} md={1.5} xl={1.1}>
                {loaders.isLoadingFilters ?
                    <Skeleton width="100%" height="30px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                :
                    <Select
                        multiple
                        sx={{ height: "30", backgroundColor: store.length > 0 && "#57C2BD" }}
                        name="store"
                        labelId="store"
                        variant="standard"
                        id="store"
                        value={store}
                        onChange={handleChangeFilter}
                        displayEmpty
                        renderValue={() => { return <span style={{ color: store.length > 0 && "white" }}>Tienda</span> }}
                        IconComponent={() => (
                            <div style={{ position: 'absolute', right: 15, pointerEvents: 'none', cursor: 'pointer' }}>
                                <img src={store.length > 0 ? whiteArrow : ArrowSelect} alt="arrow" />
                            </div>
                        )}
                    >
                        {filtersData?.stores?.map((filter, index) => (
                            <MenuItem
                                key={filter.name}
                                value={filter.name}
                                sx={{
                                    '&:hover': { backgroundColor: 'transparent' },
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent !important',
                                    borderBottom: index < filtersData?.stores.length - 1 ? '2px solid #D3D3D3' : 'none'
                                }}
                            >
                                <Checkbox
                                    icon={<img src={notSelected} />}
                                    checkedIcon={<img src={selectedCheck} />}
                                    checked={store.indexOf(filter.name) > -1}
                                    style={{ color: "#D3D3D3" }}
                                    sx={{
                                        '&:hover': { backgroundColor: 'transparent' },
                                        '&.Mui-checked': { backgroundColor: 'transparent' },
                                    }}
                                />
                                <ListItemText primary={store.includes(filter.name) ? <b>{filter.name}</b> : filter.name} />
                            </MenuItem>
                        ))}
                    </Select>
                }
                </Grid>
                <Grid item xs={6} lg={1.4} md={1.5} xl={1.1}>
                {loaders.isLoadingFilters ?
                    <Skeleton width="100%" height="30px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                :
                    <Select
                        sx={{ height: "30", backgroundColor: startDate || endDate ? "#57C2BD": undefined }}
                        name="date"
                        labelId="date"
                        variant="standard"
                        id="date"
                        displayEmpty
                        value={startDate || endDate }
                        renderValue={() => { return <span style={{ color: startDate || endDate ? "white" : undefined }}>Fecha</span> }}
                        IconComponent={() => (
                            <div style={{ position: 'absolute', right: 15, pointerEvents: 'none', cursor: 'pointer' }}>
                                <img src={startDate || endDate ? whiteArrow : ArrowSelect} alt="arrow" />
                            </div>
                        )}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                display: !showDatePicker ? 'none' : undefined, // Oculta el menú si no hay opciones
                                },
                            },
                        }}
                        onOpen={() => {setShowDatePicker(true)}}

                    >
                        {showDatePicker ?
                             <DatePicker
                                calledFrom="filtersMetricsDashboards"
                                onDateChange={handleDateChange} 
                                onClose={handlecloseNotFromButton}
                                startDate={startDate}
                                endDate={endDate}
                                selectedDate={new Date(startDate)}
                                setStartDate={setStartDate} 
                                setEndDate={setEndDate}
                                firstClick={firstClick}
                                setFirstClick={setFirstClick}
                                onCloseFromFilters={handleCloseFromButton}
                            />
                        :
                            null
                        }
                    </Select>
                }
                </Grid>
                {loaders.isLoadingFilters ?
                    <Skeleton width="30px" height="30px" style={{marginTop: 20, transform: "scale(1, 1)", marginBottom: 15, opacity: 0.35}} />
                :
                    <>
                        {isAnyFilterActive() ? (
                            <IconButton onClick={resetFilters} sx={{

                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}>
                                <img src={ResetFilters} />
                            </IconButton>
                        ): null}
                    </> 
                }

            </Grid>
        </>
    );
};

export default FiltersMetricsDashboards;