import React, { useRef, useState, useEffect } from 'react';
import classes from './PublicationInformation.module.css';
import PublicationStatus from '../../publications/Components/PublicationStatus';
import AlertTooltip from '../../publications/Components/UI/AlertTooltip';
import ProgressBar from '../../publications/Components/ProgressBar';
import EmptyPublications from '../../publications/Components/EmptyPublications';

//images
import Canasta from '../../../assets/img/canasta.png';
import Location from '../../../assets/img/icons/location.png';
import Redirect from '../../../assets/img/icons/redirect-vector.svg';
import Copy from '../../../assets/img/icons/copy-vector.svg';
import infoFortooltipIcon from '../../../assets/img/icons/info-tooltip.svg';
import Ganando from '../../../assets/img/ganando 2.png';
import Perdiendo from '../../../assets/img/Perdiendo 2.png';
import Empatando from '../../../assets/img/Empatando 2.png';

const PublicationInformation = ({ selectedPublication, setCopyResultText, setCopyCreationResult }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const tooltipHealthcheckContent = `
    <div style="position: relative; display: flex; width: 315px;">
        <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
            <p style="margin: 0;">
                <span>
                    <span style="font-weight: 500;">
                        ¡Esta es la calidad de tu publicación!
                    </span>
                    <br>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        Es fundamental para tener un mejor posicionamiento... Te desafío a llegar al 100%, no creo que sea tan difícil no?
                    </span>                
                </span>
            </p>
        </div>
    </div>`;

    const tooltipBuyboxContent = `
    <div style="position: relative; display: flex; width: 315px;">
        <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
            <p style="margin: 0;">
                <span>
                    <span style="font-weight: 500;">
                        BuyBox solo tiene 3 estados:
                    </span>
                    <br>
                    <br>
                    <span style="font-weight: 500;">
                        Ganando:
                    </span>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        Tu publicación aparece sobre la de tu competencia.
                    </span>  
                    <br>
                    <span style="font-weight: 500;">
                        Empatando:
                    </span>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        Tu publicación puede o no aparecer antes que la de tu competencia.
                    </span>  
                    <br>   
                    <span style="font-weight: 500;">
                        Perdiendo:
                    </span>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        La publicación de tu competencia está antes que la tuya...
                    </span>  
                    <br>  
                    <br>  
                    <span style="font-weight: 500;">
                        ¡No te dejes ganar!
                    </span>         
                </span>
            </p>
        </div>
    </div>`;
    
    const handleRedirectClick = (event) => {
        event.stopPropagation();
        const url = selectedPublication?.publication_url;
        if (url) {
            window.open(url, '_blank');
        }
    };

    const toSpanishNumberFormat = (number) => {
        if (number == null || isNaN(number)) { 
            return '0'; // Return '0' if number is null, undefined, or not a number
        }
        
        let numStr = number.toString().replace(/\D/g, '');
        let parts = numStr.split('.');
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? ',' + parts[1] : '';
        let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return formattedInteger + decimalPart;
    }

    function formatConversionRate(conversionRate) {
        if (conversionRate === null || conversionRate === undefined) {
            return 'Sin información';
        }
    
        const percentage = conversionRate * 100;
        const decimals = percentage === 0 || Number.isInteger(percentage) ? 0 : 2;
    
        // Format the number with thousand separators
        const [integerPart, decimalPart] = percentage
            .toFixed(decimals)
            .split('.');
            
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    
        return decimalPart
            ? `${formattedInteger},${decimalPart}%`
            : `${formattedInteger}%`;
    }
    

    const transformTranslatedStatus = (status) => {
        if (status === 'Rechazado') {
            return 'Rechazada';
        }
        if (status === 'Publicado') {
            return 'Publicada';
        }
        return status;
    };

    const statusData = {
        status: selectedPublication?.status,
        translatedStatus: transformTranslatedStatus(selectedPublication?.translated_status),
        caller: 'publications',
    };

    const handleCopyPublicationId = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(selectedPublication.sku);
    };

    const handleCopyPublicationEan = (event) => {
        event.stopPropagation();
        handleCopyToClipboard(selectedPublication.ean);
    };

    const handleCopyToClipboard = (text = '') => {
        navigator.clipboard.writeText(text);
        setCopyResultText("1, 2, 3 ¡Código copiado!");
        setCopyCreationResult(true); 
    };

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.headerContainer}>
                <div className={classes.channelIconContainer}>
                    <img className={classes.channelIcon} src={selectedPublication.channel_icon} />
                    <img className={classes.cardInfoRedirectIcon} src={Redirect} alt="Redirect Icon" onClick={handleRedirectClick} />
                </div>  
                <div className={classes.locationContainer}>
                    <img className={classes.locationIcon} src={Location} alt="Location Icon" />  
                    <span className={classes.locationText}>{'Sin info'}</span>                  
                </div>   
                <div className={classes.stockContainer}>
                    <img className={classes.basketIcon} src={Canasta} alt="Basket Icon" />  
                    <span className={classes.stockText}>{selectedPublication?.stock ? toSpanishNumberFormat(selectedPublication?.stock) : 0}</span>                  
                </div>                        
            </div>  
            <div className={classes.publicationContainer}>
                <div className={classes.publicationLeftContainer}>
                    <div className={classes.publicationImageContainer}>
                        <img className={classes.publicationImage} width={windowWidth < 1300 ? '80%' : '100%'} src={selectedPublication?.images[0]?.link} alt="Publication image" /> 
                    </div>
                    <div className={classes.publicationStatus}>
                        <PublicationStatus statusData={statusData} />
                    </div>
                </div>
                <div className={classes.publicationRightContainer}>
                    <div className={classes.publicationIds}>
                        <div className={classes.textIdSKU}>
                            <span className={classes.textId}>SKU: {selectedPublication.sku}</span>
                            <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationId} />
                        </div>
                        <div className={classes.textIdEAN}>
                            <span className={classes.textId}>EAN: {selectedPublication.ean}</span>
                            <img className={classes.textIdCopy} src={Copy} alt="Copy" onClick={handleCopyPublicationEan} />
                        </div>
                    </div>
                    <div className={classes.publicationNames}>
                        <div className={classes.textClientWrapper}>
                            <span className={classes.textClient}>{selectedPublication.client}</span>
                        </div>
                        <div className={classes.textCategoryWrapper}>
                            <span className={classes.textCategory}>{selectedPublication.category}</span>
                        </div>
                        <div className={classes.textNameWrapper}>
                            <span className={classes.textTitle}>{selectedPublication.name}</span>
                        </div>
                    </div>
                    { selectedPublication?.sale_price ? 
                        (
                            <div className={classes.publicationPrice}>
                                <div className={classes.publicationOriginalPriceContainer}>  
                                    { selectedPublication?.sale_price?.regular_price ? 
                                        (
                                            <span className={selectedPublication?.sale_price?.price != selectedPublication?.sale_price?.regular_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormat(selectedPublication?.sale_price?.regular_price)}</span>                           
                                        ) 
                                        : 
                                        (
                                            selectedPublication?.original_price ? 
                                            (
                                                <span className={selectedPublication?.sale_price?.price != selectedPublication?.original_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormat(selectedPublication?.original_price)}</span>                           
                                            ) 
                                            : 
                                            (
                                                <span className={selectedPublication?.sale_price?.price != selectedPublication?.price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormat(selectedPublication?.price)}</span>                           
                                            )
                                        ) 
                                    }                                                              
                                </div>
                                <div className={classes.publicationCurrentPriceContainer}>
                                    <span className={classes.currentPrice}>${selectedPublication?.sale_price?.price ? toSpanishNumberFormat(selectedPublication?.sale_price?.price) : toSpanishNumberFormat(selectedPublication?.price)}</span>                           
                                </div>
                            </div>
                        ) 
                        : 
                        (
                            <div className={classes.publicationPrice}>
                                <div className={classes.publicationOriginalPriceContainer}>   
                                    { selectedPublication?.original_price ? 
                                        (
                                            <span className={selectedPublication?.price != selectedPublication?.original_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormat(selectedPublication?.original_price)}</span>                           
                                        ) 
                                        : 
                                        (
                                            selectedPublication?.base_price ? 
                                            (
                                                <span className={selectedPublication?.price < selectedPublication?.base_price ? classes.basePrice : classes.noBasePrice}>${toSpanishNumberFormat(selectedPublication?.base_price)}</span>                           
                                            ) 
                                            : 
                                            (
                                                <span className={classes.noBasePrice}>$</span>                           
                                            )
                                        )
                                    }                                                              
                                </div>
                                <div className={classes.publicationCurrentPriceContainer}>
                                    <span className={classes.currentPrice}>${toSpanishNumberFormat(selectedPublication?.price)}</span>                           
                                </div>
                            </div>
                        )
                    }                    
                </div>
            </div>  
            <div className={classes.dataContainer}>
                <div className={classes.dataLeftContainer}>
                    <div className={classes.healthBuyboxContainer}>
                        {selectedPublication?.has_healthcheck && !selectedPublication?.has_buybox && 
                            (
                                <>
                                    <div className={classes.healthcheckBuyboxTextContainer}>
                                        <span className={classes.healthcheckBuyboxText}>Healthcheck</span>
                                        <AlertTooltip tooltipContent={tooltipHealthcheckContent}>
                                            <img
                                                className={classes.infoIconForTootltipInfo}
                                                alt="info"
                                                src={infoFortooltipIcon}
                                            />
                                        </AlertTooltip>
                                    </div>
                                    <div className={classes.healthcheckBuyboxContentContainer}>
                                        <div className={classes.healthcheck}>
                                            <ProgressBar percentage={(selectedPublication.healthcheck?.health || 0) * 100} strokeWidth={10} isDefaultColor={true} defaultFontSize={'24px'} defaultFontWeight={'700'} />
                                        </div>                                        
                                    </div>
                                </>
                            )
                        }
                        {selectedPublication?.has_buybox && 
                            (
                                <>
                                    <div className={classes.healthcheckBuyboxTextContainer}>
                                        <span className={classes.healthcheckBuyboxText}>Buybox</span>
                                        <AlertTooltip tooltipContent={tooltipBuyboxContent}>
                                            <img
                                                className={classes.infoIconForTootltipInfo}
                                                alt="info"
                                                src={infoFortooltipIcon}
                                            />
                                        </AlertTooltip>
                                    </div>
                                    <div className={classes.healthcheckBuyboxContentContainer}>
                                        <div className={classes.buybox}>
                                            {['winning'].includes(selectedPublication.buybox.status) && (
                                                <img className={classes.imgBuyBox} src={Ganando} alt="Winning" />
                                            )}
                                            {!['winning', 'sharing_first_place'].includes(selectedPublication.buybox.status) && (
                                                <img className={classes.imgBuyBox} src={Perdiendo} alt="Losing" />
                                            )}
                                            {['sharing_first_place'].includes(selectedPublication.buybox.status) && (
                                                <img className={classes.imgBuyBox} src={Empatando} alt="Tying" />
                                            )}
                                        </div>  
                                    </div>
                                </>
                            )
                        }
                        {!selectedPublication?.has_healthcheck && !selectedPublication?.has_buybox && 
                            (
                                <>
                                    <div className={classes.healthcheckBuyboxTextContainer}>
                                        <span className={classes.healthcheckBuyboxText}>Healthcheck y Buybox</span>
                                    </div>
                                    <div className={classes.healthcheckBuyboxContentContainer}>
                                        <div className={classes.noBuyboxHealthcheck}>
                                            <EmptyPublications calledFrom="publicationsList" />
                                        </div>  
                                    </div>
                                </>
                            )
                        }
                    </div> 
                    <div className={classes.wordsContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Palabras descripción</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{toSpanishNumberFormat(selectedPublication?.statistics?.long_description_word_count)}</span>  
                        </div>
                    </div> 
                    <div className={classes.reviewsContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Reviews totales</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{selectedPublication?.reviews_quantity  >= 0 ? toSpanishNumberFormat(selectedPublication?.reviews_quantity) : 'Sin información'}</span>  
                        </div>
                    </div> 
                    <div className={classes.clicksContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Visitas últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{selectedPublication?.statistics?.last_7_days_visits ? toSpanishNumberFormat(selectedPublication?.statistics?.last_7_days_visits) : 'Sin información'}</span>  
                        </div>
                    </div> 
                </div> 
                <div className={classes.dataRightContainer}>
                    <div className={classes.salesContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Ventas últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>${toSpanishNumberFormat(selectedPublication?.statistics?.total_sales_in_money_last_7_days)}</span>  
                        </div>
                    </div> 
                    <div className={classes.lastSalesContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Pedidos últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{toSpanishNumberFormat(selectedPublication?.statistics?.total_sales_last_7_days)}</span>  
                        </div>
                    </div> 
                    <div className={classes.imagesContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Imágenes</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{toSpanishNumberFormat(selectedPublication?.images?.length)}</span>  
                        </div>
                    </div> 
                    <div className={classes.comentsContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Comentarios totales</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>{selectedPublication?.statistics?.comments_quantity >= 0 ? toSpanishNumberFormat(selectedPublication?.statistics?.comments_quantity) : 'Sin información'}</span>  
                        </div>
                    </div> 
                    <div className={classes.conversionContainer}>
                        <div className={classes.commonTextContainer}>
                            <span className={classes.commonText}>Conversión últimos 7 días</span>
                        </div>
                        <div className={classes.commonContentContainer}>
                            <span className={classes.commonTextContent}>
                                {formatConversionRate(selectedPublication?.statistics?.last_7_days_conversion)}
                            </span>
                        </div>
                    </div> 
                </div> 
            </div>         
        </div>       
    );
}    

export default PublicationInformation;
